.contact-section {
    padding: 50px 0;
    text-align: center;
    background-color: #f8f9fa;
}

.contact-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    position: relative;
}

.contact-title::after {
    content: "";
    display: block;
    width: 50px;
    height: 3px;
    background-color: #00aaff;
    margin: 10px auto 20px;
}

/* Centrer le formulaire de contact */
.contact-content {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

/* Formulaire de contact */
.contact-form {
    width: 100%;
    text-align: left;
}

.contact-form h3 {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.form-group {
    display: flex;
    gap: 10px;
}

.form-group input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
}

textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 15px;
    resize: vertical;
    font-size: 1rem;
}

.send-message-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #00aaff;
    color: #fff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
}

.send-message-btn:hover {
    background-color: #0077cc;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .contact-title {
        font-size: 2rem;
    }

    .contact-content {
        padding: 0 15px;
    }

    .form-group {
        flex-direction: column;
        gap: 0;
    }

    .form-group input {
        width: 100%;
        margin-bottom: 10px;
    }
}

@media (max-width: 480px) {
    .contact-title {
        font-size: 1.8rem;
    }

    .send-message-btn {
        font-size: 0.9rem;
        padding: 8px 16px;
    }
}
