.side-menu {
    width: 250px;
    background-color: #1c1c1c;
    color: white;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

/* Cache le SideMenu sur les petits écrans */
@media (max-width: 950px) {
    .side-menu {
        display: none !important;
    }
}

/* Autres styles existants */
.profile-section {
    text-align: center;
    margin-bottom: 20px;
}

.profile-pic {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 15px;
}

.navigation {
    padding-top: 0;
    width: 100%;
    text-align: center;
}

.navigation ul {
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
}

.navigation ul li {
    margin: 55px 0;
}

.navigation ul li a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    padding: 10px;
    transition: color 0.3s ease, background-color 0.3s ease;
}

.navigation ul li a:hover {
    color: #00aaff;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
}


.navigation ul li a.active {
    color: #ffffff;
    background-color: #00aaff;
    border-radius: 5px;
}
