/* Header pour le menu burger */
.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #1c1c1c;
    color: white;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    height: 60px; /* Assure une hauteur suffisante pour que le burger soit centré */
}

/* Positionnement du nom */
.name {
    font-size: 1.2rem;
    font-weight: bold;
}

/* Bouton burger bien positionné */
.burger-icon {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-right: 30px; /* Pour ajuster l'espace à droite */
    align-items: flex-end;
}

.burger-icon span {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px 0;
    transition: 0.4s;
}

/* Overlay Menu */
.overlay-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1c1c1c;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 200;
}

/* Bouton de fermeture dans l'overlay */
.overlay-menu .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2rem;
    cursor: pointer;
}

/* Navigation dans l'overlay */
.overlay-menu .navigation ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

.overlay-menu .navigation ul li {
    margin: 20px 0;
}

.overlay-menu .navigation ul li a {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
    transition: color 0.3s ease;
}

.overlay-menu .navigation ul li a:hover {
    color: #00aaff;
}
