.hero-section {
    height: 100vh; /* Occupe toute la hauteur de la fenêtre */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: white;
    text-align: center;
}

.hero-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.hero-section .content {
    margin-left: 0;
    position: relative;
    z-index: 2;
    padding: 20px; /* Ajout d’un padding pour l'espace sur petits écrans */
}

.hero-section h1 {
    font-size: 2rem;
    margin: 0;
}

.hero-section h2 {
    font-size: 4rem;
    margin: 0;
}

.hero-section p {
    font-size: 1.5rem;
    margin-top: 20px;
    color: white;
}

.Btn-contact {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1.2rem;
    color: #00aaff;
    background-color: transparent;
    border: 2px solid #00aaff;
    text-decoration: none;
    border-radius: 100px;
    transition: all 0.3s ease;
}

.Btn-contact:hover {
    color: white;
    background-color: #00aaff;
    border: 2px solid #00aaff;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .hero-section h1 {
        font-size: 1.8rem;
    }

    .hero-section h2 {
        font-size: 3.5rem;
    }

    .hero-section p {
        font-size: 1.3rem;
    }

    .Btn-contact {
        font-size: 1rem;
        padding: 8px 16px;
    }
}

@media (max-width: 768px) {
    .hero-section h1 {
        font-size: 1.5rem;
    }

    .hero-section h2 {
        font-size: 2.5rem;
    }

    .hero-section p {
        font-size: 1.2rem;
    }

    .Btn-contact {
        font-size: 0.9rem;
        padding: 8px 14px;
    }
}

@media (max-width: 480px) {
    .hero-section h1 {
        font-size: 1.2rem;
    }

    .hero-section h2 {
        font-size: 2rem;
    }

    .hero-section p {
        font-size: 1rem;
    }

    .Btn-contact {
        font-size: 0.8rem;
        padding: 6px 12px;
    }
}
