.about-me-section {
    min-height: 100vh;
    padding: 0;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 20px; /* Espace pour éviter le chevauchement avec la HeroSection */
}

.container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding-top: 30px;
}

.col-left {
    flex: 2;
    padding-right: 20px;
    text-align: left;
}

.col-right {
    flex: 1;
    padding-left: 20px;
    padding-top: 15px;
    text-align: left;
}

.about-title {
    font-size: 2.5rem;
    margin-bottom: 40px;
    position: relative;
    font-weight: bold;
    text-align: center;
    z-index: 1;
}

.about-title::before {
    content: "ABOUT ME";
    font-size: 4rem;
    color: rgba(0, 0, 0, 0.05);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    pointer-events: none;
}

.about-title::after {
    content: "";
    width: 150px;
    height: 3px;
    background-color: #00aaff;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

h3 {
    font-size: 1.8rem;
    margin-bottom: 20px;
}

.highlight {
    color: #00aaff;
}

p {
    font-size: 1rem;
    line-height: 1.8;
    margin-bottom: 20px;
    color: #333;
}

.personal-info {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
    color: #333;
}

.personal-info li {
    margin-bottom: 10px;
}

.download-cv-btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1.2rem;
    color: #00aaff;
    background-color: transparent;
    border: 2px solid #00aaff;
    text-decoration: none;
    border-radius: 50px;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-top: 20px;
}

.download-cv-btn:hover {
    background-color: #00aaff;
    color: white;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .about-title {
        font-size: 2.2rem;
    }

    .about-title::before {
        font-size: 3.5rem;
    }

    .col-left, .col-right {
        padding: 10px;
    }

    h3 {
        font-size: 1.6rem;
    }

    p {
        font-size: 0.9rem;
    }
}

@media (max-width: 768px) {
    .row {
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
    }

    .col-left, .col-right {
        padding: 0;
        width: 100%;
        text-align: center;
    }

    .about-title {
        font-size: 2rem;
    }

    .about-title::before {
        font-size: 3rem;
    }

    h3 {
        font-size: 1.4rem;
    }

    p {
        font-size: 0.9rem;
    }

    .download-cv-btn {
        font-size: 1rem;
        padding: 8px 16px;
    }
}

@media (max-width: 480px) {
    .about-title {
        font-size: 1.8rem;
    }

    .about-title::before {
        font-size: 2.5rem;
    }

    h3 {
        font-size: 1.2rem;
    }

    p {
        font-size: 0.8rem;
    }

    .download-cv-btn {
        font-size: 0.9rem;
        padding: 6px 14px;
    }
}
