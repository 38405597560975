.what-i-do-section {
    background-color: #f9f9f9;
    padding: 20px 0;
}

.container {
    max-width: 1200px;
    padding: 0 20px;
    margin-bottom: 45px;
}

.whatido-title {
    font-size: 2.5rem;
    margin-bottom: 40px;
    padding-top: 20px;
    position: relative;
    font-weight: bold;
    text-align: center;
    z-index: 1;
}

.whatido-title::before {
    content: "SERVICES";
    font-size: 4.5rem;
    color: rgba(0, 0, 0, 0.05);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    pointer-events: none;
}

.whatido-title::after {
    content: "";
    width: 150px;
    height: 3px;
    background-color: #00aaff;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.services-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-evenly;
}

.service-item {
    flex: 0 1 calc(50% - 20px); /* Chaque élément occupe 50% de la largeur */
    box-sizing: border-box;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.service-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
}

.service-item h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
}

.service-item p {
    font-size: 1rem;
    line-height: 1.6;
    color: #666;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .whatido-title {
        font-size: 2.2rem;
    }

    .whatido-title::before {
        font-size: 4rem;
    }
}

@media (max-width: 768px) {
    .service-item {
        flex: 0 1 100%; /* Chaque élément occupe toute la largeur sur les écrans moyens */
        max-width: 100%;
        margin-bottom: 20px;
    }

    .whatido-title {
        font-size: 2rem;
    }

    .whatido-title::before {
        font-size: 3.5rem;
    }
}

@media (max-width: 480px) {
    .whatido-title {
        font-size: 1.8rem;
    }

    .whatido-title::before {
        font-size: 3rem;
    }

    .service-item h3 {
        font-size: 1.2rem;
    }

    .service-item p {
        font-size: 0.9rem;
    }

    .service-icon {
        width: 40px;
        height: 40px;
    }
}
