.home-container {
    display: flex;
    width: 100%;
}

.content {
    flex: 1;
    align-items: center;
    margin-left: 250px; /* Pour les écrans larges */
}

/* Supprime la marge pour les petits écrans */
@media (max-width: 950px) {
    .content {
        margin-left: 0;
    }
}
