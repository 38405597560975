.footer {
    padding: 20px;
    text-align: center;
    background-color: #f8f9fa;
    color: #333;
    font-size: 0.9rem;
}

.footer-name {
    color: #00aaff; /* Couleur bleu pour le nom */
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-name:hover {
    color: #0077cc; /* Couleur plus sombre au survol */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .footer {
        font-size: 0.8rem;
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .footer {
        font-size: 0.7rem;
        padding: 10px;
    }
}
